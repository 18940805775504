<template>
  <div class="panic-buy">
    <div class="title">
      <img src="https://img.xinshu.me/resource/8a1b78a253c84d15a7ddd9a26f0517e2" alt="">
    </div>
    <div class="goods-wrapper">
      <div class="goods-card" v-for="item in goodsList" :key="item.id" @click="goBuy(item.id)">
        <div class="card-top">
          <div class="title">{{item.title}}</div>
          <div class="pic">
            <img :src="item.pic" alt="">
          </div>
          <div class="info">{{item.info}}</div>
        </div>
        <div class="card-bottom">
          <div class="price">限时价:￥{{item.price}}</div>
          <div class="old-price">原价:￥{{item.oldPrice}}</div>
          <div class="buy-btn">抢购</div>
        </div>
      </div>
    </div>
    <div class="back" @click="goShelf">前往书架</div>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'

export default {
  name: 'panicBuy',
  title: '微信书七周年抢购',
  data() {
    return {
      goodsList: [
        {
          id: 'IQOVCM',
          title: '100元全场券',
          pic: 'https://img.xinshu.me/resource/e360159cc0304bb8affe269b230e7f38',
          info: '做书囤券，可与会员折扣叠加使用',
          price: '77',
          oldPrice: '100'
        },
        {
          id: 'QSFSRA',
          title: 'LOMO卡 60张',
          pic: 'https://img.xinshu.me/resource/10732c2f61504b548ae3ac2b44074e73',
          info: '260G进口相纸',
          price: '18',
          oldPrice: '30'
        },
        {
          id: 'BDWDYO',
          title: '锁线精装照片书',
          pic: 'https://img.xinshu.me/resource/07547f445fc34cbfa3da7e5872d0c40f',
          info: '一页一张照片，95张照片，极简设计，极速完成',
          price: '59',
          oldPrice: '98'
        },
        {
          id: 'RMLKET',
          title: '朋友圈相框',
          pic: 'https://img.xinshu.me/resource/1311816830c04b5daac7993f80c7c2ea',
          info: '精美摆台，三种样式，让回忆更温暖',
          price: '27',
          oldPrice: '38'
        },
        {
          id: 'NZTMXR',
          title: '亲子笔记本',
          pic: 'https://img.xinshu.me/resource/d99a36d252784a3b9ccc9b4a951e3cad',
          info: '精美笔记本，一大一小，共同记录',
          price: '24',
          oldPrice: '39'
        },
        {
          id: 'VHUVKJ',
          title: 'A4精装纪念册',
          pic: 'https://img.xinshu.me/resource/7333621951904d97b6aa919fac1a361b',
          info: '对裱装订，108°摊开，最多可上传40张照片',
          price: '38',
          oldPrice: '78'
        },
        {
          id: 'UJDRBM',
          title: '蝴蝶写真集',
          pic: 'https://img.xinshu.me/resource/75a0ce8c08524a9a80dacd1ebdda1a9b',
          info: '12寸，高清艺术纸，可传10-40张照片',
          price: '49',
          oldPrice: '99'
        },
        {
          id: 'QJXISY',
          title: '儿童创意绘本',
          pic: 'https://img.xinshu.me/resource/a7ae6bd1ed8c49e5a477e9c384bacf93',
          info: '上传即可自动抠图，一键生成属于宝宝的绘本',
          price: '38',
          oldPrice: '68'
        }
      ]
    }
  },
  mounted() {
    configShare({
      title: '微信书七周年抢购',
      desc: '做书囤券 低至4.8折',
      link: location.href,
      imgUrl: 'https://img.xinshu.me/resource/81862b17ea7541fc95af64d405550229'
    })
  },
  methods: {
    goShelf() {
      this.$router.push('/books')
    },
    goBuy(id) {
      this.$router.push(`/packages/${id}`)
    }
  }
}
</script>

<style lang="scss">
body[data-page="panicBuy"] {

  main {
    padding-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
$mainColor: #F64C3D;

.panic-buy {
  max-width: 480px;
  min-height: 100vh;
  padding: 0;
  background-image: linear-gradient(rgb(216, 65, 65), rgb(230, 112, 74), rgb(234, 122, 77));

  .goods-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px 0 20px;

    .goods-card {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 0 5px rgba(255, 255, 255, .3);
      flex: 1;
      width: 47.4%;
      min-width: 47.4%;
      max-width: 47.4%;
      margin-bottom: 20px;
      overflow: hidden;

      .card-top {
        height: 200px;
        background-color: rgb(254, 247, 241);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 14px 0 6px 0;

        .title {
          font-size: 16px;
          font-weight: bold;
        }

        .pic {
          height: 100px;
          margin: 10px 0;

          img {
            height: 100%;
            width: auto;
          }
        }

        .info {
          width: 90%;
          text-align: center;
          color: rgb(110, 113, 120);
          font-size: 12px;
        }
      }

      .card-bottom {
        position: relative;
        padding: 10px;

        .price {
          font-size: 18px;
          font-weight: bold;
          color: $mainColor;
        }

        .old-price {
          color: rgb(110, 113, 120);
          text-decoration: line-through;
        }

        .buy-btn {
          position: absolute;
          right: 10px;
          bottom: 10px;
          background-color: $mainColor;
          color: #fff;
          border-radius: 6px;
          padding: 2px 10px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .back {
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 10px 0 40px 0;
    cursor: pointer;
  }
}
</style>
